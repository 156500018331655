import React from "react";
import ReactDOM from "react-dom";

import "baasic-react-bootstrap/node_modules/@ag-grid-community/core/dist/styles/ag-grid.css";
import "baasic-react-bootstrap/node_modules/@ag-grid-community/core/dist/styles/ag-theme-alpine.css";
import "themes/styles/postcss/app.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { applicationBootstrapper } from "mrb/core";
import { RootStore } from "common/stores";

//import * as Sentry from "@sentry/react";

// Sentry.init({
//     dsn: "https://a84bb72a732e2379a47b69759f31fe3b@o4506970669711360.ingest.us.sentry.io/4506970672070656",
//     integrations: [
//         Sentry.browserTracingIntegration(),
//         Sentry.replayIntegration({
//             maskAllText: false,
//             blockAllMedia: false,
//         }),
//     ],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions
//     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: ["https://portal.nakiradio.com"],
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

function startApplication() {
    const configuration = applicationBootstrapper.run(["application"]);
    const rootStore = new RootStore(configuration);

    const rootLoader = document.getElementById("root-loader");
    if (rootLoader) {
        rootLoader.remove();
    }

    renderApplication({ rootStore });
}

function renderApplication({ rootStore }) {
    ReactDOM.render(
        // <React.StrictMode>
        //     <App rootStore={rootStore} />
        // </React.StrictMode>,
        <App rootStore={rootStore} />,
        document.getElementById("root")
    );
}

startApplication();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
